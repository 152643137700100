import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class DfLoadingMaskComponent extends Vue {
  @Prop({ type: String, default: "page" })
  type: string;
  @Prop({ type: Boolean, default: true })
  background: boolean;

}
